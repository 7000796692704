<script setup>
	const { image, src, alt, lazy, quality, forceLoad, width, height, mod, isTransparent, sizes } = defineProps({
		image: {
			type: Object,
			required: false,
			default: {},
		},
		src: {
			type: String,
			required: false,
			default: null,
		},
		alt: {
			type: String,
			required: false,
			default: null,
		},
		lazy: {
			type: Boolean,
			required: false,
			default: true,
		},
		quality: {
			type: Number,
			required: false,
			default: 50,
		},
		forceLoad: {
			type: Boolean,
			required: false,
			default: false,
		},
		height: {
			type: [Number, Boolean],
			required: false,
			default: false,
		},
		width: {
			type: [Number, Boolean],
			required: false,
			default: false,
		},
		mod: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		sizes: {
			type: String,
			required: false,
			default: '',
		},
		isTransparent: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const loadingValue = lazy ? 'lazy' : 'eager';

	// Setup URL
	let url = image?.src || image?.url || image?.image || src;

	// Define URLs to remove:
	// NuxtImage should resolve the correct URL for the environment
	// based on the providers base URL
	const config = useRuntimeConfig();
	const { imageUrlsToRemove } = config.public;
	const urlsToRemove = imageUrlsToRemove.split(',');

	// Check if the URL contains any of the URLs to remove
	const shouldRemove = urlsToRemove.some((url) => {
		return image?.src?.includes(url) || image?.url?.includes(url) || image?.image?.includes(url) || src?.includes(url);
	});

	if (shouldRemove) {
		// Remove the URL from the image src
		urlsToRemove.forEach((aUrl) => {
			url = url.replace(aUrl, '');
		});
	}

	const lowerCase = url?.toLowerCase() || '';
	const transparent =
		lowerCase?.includes('.png') || lowerCase?.includes('.svg') || lowerCase?.includes('.gif') || isTransparent;
	const shouldPreload = lazy && !forceLoad && !transparent;

	const hasImage = image || src;

	const maxWidth = 1920;
	let setWidth = width || maxWidth || null;
	if (setWidth && setWidth > maxWidth) {
		setWidth = maxWidth;
	}

	const baseModifier = {
		w: setWidth,
		q: quality,
		h: height || null,
		fit: 'max',
		fm: 'webp',
		...mod,
	};

	if (sizes != '100vw') {
		delete baseModifier.w;
		delete baseModifier.h;
	}
</script>

<template>
	<div class="mess-image">
		<nuxt-img
			v-if="hasImage && shouldPreload && (url !== null)"
			:quality="quality"
			loading="eager"
			:src="url"
			:alt="alt || image.alt || null"
			:sizes="sizes"
			:modifiers="{ ...baseModifier, w: 10, h: 'auto', q: 1 }"
		/>
		<nuxt-img
			v-if="hasImage && shouldPreload && (url !== null)"
			:loading="loadingValue"
			:src="url"
			:alt="alt || image.alt || null"
			class="full-sized-image"
			:sizes="sizes"
			:modifiers="{ ...baseModifier }"
		/>
		<nuxt-img
			v-if="hasImage && !shouldPreload && (url !== null)"
			loading="eager"
			:src="url"
			:alt="alt || image.alt || null"
			:sizes="sizes"
			:modifiers="{ ...baseModifier }"
		/>
		<div v-if="url === null" class="placeholder">
		</div>
	</div>
</template>

<style lang="scss">
	.mess-image {
		position: relative;

		> img {
			height: 100%;
			width: 100%;
			object-fit: cover;

			&.full-sized-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
		}

		.placeholder {
			width: 100%;
			height: 100%;
			background-color: var(--red);
		}
	}
</style>
